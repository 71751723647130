import { Atom, SHARED_VALIDATIONS, helper } from ":mods";

const is_dev = !helper.env.isProd;
const mocks = {
  admin: {
    email: "admin@gmail.com",
    password: "123123",
  },
  student: {
    email: "abdu@gmail.com",
    password: "Ali123123@",
  },
};

export function createAccountInputs(mock?: keyof typeof mocks) {
  const m = is_dev && mock ? mocks[mock] : undefined;
  const Email = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.email.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Password = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const err = SHARED_VALIDATIONS.password.validate(value);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const ConfirmPassword = Atom.Form.createInput("", {
    required: true,
    validators: (value) => {
      const original_password = Password.control.value;
      const err = (!!value && original_password !== value) && "Password not matched";
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Actions = Atom.Form.createFormActions({ email:Email, password:Password, confirm_password:ConfirmPassword });
  return {
    Actions,
    Email,
    Password,
    ConfirmPassword
  };
}
